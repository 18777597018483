import React from "react"
import { graphql, Link, withPrefix } from "gatsby"
import { Helmet } from "react-helmet"

import { handelize } from "../utils/utils"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ArtworkCaption = ({
  Artist,
  Name,
  Year,
  Artwork_Type,
  Web_Description,
  Height,
  Width,
  Unit_Price,
  Location,
  Status,
  Framestyle,
}) => (
  <>
    <p className="text-xs md:text-sm">
      {Name && <span className="italic">{Name}</span>}
      {Year && (
        <>
          <br />
          {Year}
        </>
      )}
      {Artwork_Type && (
        <>
          <br />
          {Artwork_Type}
        </>
      )}
      {Width && Height && (
        <>
          <br />
          {Height}" x {Width}"
        </>
      )}
      {Unit_Price && (
        <>
          <br />${Unit_Price}
        </>
      )}
      {Status && (
        <>
          <br />
          {Status}
        </>
      )}
      {Location && (
        <>
          <br />
          {Location}
        </>
      )}
      {Framestyle && (
        <>
          <br />
          {Framestyle}
        </>
      )}
    </p>
    {Web_Description && (
      <p className="text-sm max-w-lg-mx-auto">{Web_Description}</p>
    )}
  </>
)

const ArtworkTemplate = props => {
  const { data } = props.data.airtable

  return (
    <Layout>
      <Helmet>
        <link
          type="text/css"
          rel="stylesheet"
          href={withPrefix("magiczoomplus.css")}
        />
        <script src={withPrefix("magiczoomplus.js")} type="text/javascript" />
      </Helmet>
      <SEO title={data.Name} description={data.Web_Description} />
      <div className="max-w-6xl mx-auto px-4 my-8 w-full">
        <div className="text-center">
          <h1 className="mt-4 mb-2">{data.Name}</h1>
          {data.Artist && (
            <h4 className="mb-4">
              By{" "}
              <Link to={`/${handelize(data.Artist[0].data.Name)}`}>
                {data.Artist[0].data.Name}
              </Link>
            </h4>
          )}
        </div>
        {Boolean(data.smallImages) &&
          Boolean(data.smallImages.localFiles) &&
          data.smallImages.localFiles.map((_, i) => {
            const smallImage =
              data.smallImages.localFiles[i].childImageSharp.fixed.src
            const largeImage =
              data.largeImages.localFiles[i].childImageSharp.fixed.src

            return (
              <div key={i} className="mb-8 text-center relative">
                <a
                  href={largeImage}
                  className="MagicZoom"
                  data-options="zoomWidth:350px; zoomHeight:350px"
                >
                  <img
                    src={smallImage}
                    alt={data.Name}
                    className="max-h-80vh"
                  />
                </a>
              </div>
            )
          })}
        <div className="text-center">
          <ArtworkCaption {...data} />
        </div>
        {data.Artist && (
          <div className="text-center mt-8 flex justify-center">
            <Link
              to={`/${handelize(data.Artist[0].data.Name)}`}
              className="mx-4"
            >
              View Artist
            </Link>{" "}
            |
            <a
              href={`mailto:tnlg23@gmail.com?subject=Website inquiry about purchasing ${
                data.Name
              }${data.Artist ? ` by ${data.Artist[0].data.Name}` : ``}`}
              className="mx-4"
            >
              Purchase
            </a>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default ArtworkTemplate

export const query = graphql`
  query ArtworkByRecordId($recordId: String!) {
    airtable(recordId: { eq: $recordId }) {
      data {
        Name
        smallImages: Images {
          localFiles {
            childImageSharp {
              fixed(width: 600) {
                src
              }
            }
          }
        }
        largeImages: Images {
          localFiles {
            childImageSharp {
              fixed(width: 1200) {
                src
              }
            }
          }
        }
        Year
        Web_Description
        Location
        Artwork_Type
        Height
        Width
        Status
        Framestyle
        Unit_Price
        Artist {
          data {
            Name
          }
        }
      }
    }
  }
`
